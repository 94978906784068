import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { Container, Row, Col } from "react-bootstrap"
import "../components/fragments"
import Layout from "../components/layout"
import Seo from "../components/seo"
import EventTeaser from "../components/eventTeaser"
import Pager from "../components/pager"
import EventsNav from "../components/eventsNav"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import { getCrumbsObj } from "../utils/ug-utils"
import BannerAd, { BANNERAD_CHILDREN_EVENT } from "../components/bannerAd"

import "../styles/event.scss"

const EventTypePage = ({ data, pageContext }) => {
  const { id, name } = pageContext
  const eventsData = data.events.edges
  const crumbsObj = getCrumbsObj(pageContext)

  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class: "event-list-page",
          "aria-label": "Event List Page"
        }}
      />
      <Seo title={`Events - ${name}`} keywords={[`gatsby`, `application`, `react`]} />

      {/**** Header and Title ****/}
      <div id="rotator" className="shorter-hero">
        <div className="container ft-container">
          <h1 className="fancy-title">Events</h1>
        </div>
      </div>

      {/**** Body content ****/}
      <div className="page-container">
        <Container>
          {crumbsObj.crumbs && <Breadcrumb {...crumbsObj} aria-label="Events List"/>}
          <Row className="row-with-vspace site-content">
            <EventsNav selected={`type/${id}/past`} />
          </Row>
          <Row className="row-with-vspace site-content">
            <Col md={12} className="content-area">
              {/**** Body content ****/}
              <Container>
                {eventsData.length > 0 ? (
                  <>
                    {eventsData.map(({ node }, index) => {
                      return (
                        <>
                          <EventTeaser node={node} />
                        </>
                      )
                    })}
                  </>
                ) : (
                  <p>No events to display.</p>
                )}
              </Container>
              <Pager {...pageContext} />
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default EventTypePage

export const query = graphql`
  query ($id: String, $currentDate: Date!, $skip: Int!, $limit: Int!) {
    events: allNodeEvent(
      limit: $limit
      skip: $skip
      filter: { field_event_type: { eq: $id }, field_date: { end_value: { lt: $currentDate } } }
      sort: { fields: field_date___value, order: DESC }
    ) {
      edges {
        node {
          ...Event
        }
      }
    }
  }
`
